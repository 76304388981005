





































































// Core
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'

// Mixins
import GetStoreMixin from '@mixins/GetStore'

// Store
import {worksNamespace} from '@store/works'

// Types
import {WorksActionTypes} from '@store/works/Types'

// Interfaces
import {IWork, IWorkStruct} from '@store/works/Interface'
import Debounce from "@/decorators/Debounce";

@Component({
  components: {
    'v-work-struct-aside': () => import('@views/workList/components/WorkStructAside.vue'),
    'v-work-card': () => import('@views/workList/components/WorkCard.vue'),
    'v-work-struct-card': () => import('@views/workList/components/WorkStructCard.vue'),
    'v-add-edit-form': () => import('@/views/workList/components/WorkListAddEditWorkStructForm.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})

export default class WorkListStruct extends Mixins(GetStoreMixin) {
  @worksNamespace.Action(WorksActionTypes.A_WORKS)
  private fetchWorks!: (params) => Promise<void>

  @worksNamespace.Action(WorksActionTypes.A_FETCH_WORK_STRUCTS)
  private fetchWorkStructs!: (params) => Promise<void>

  @worksNamespace.State('works')
  private worksList!: IWork[]

  @worksNamespace.State('workStructs')
  private workStructsList!: IWorkStruct[]

  @worksNamespace.State('lastPage')
  private worksLastPage!: number

  @Prop(String) readonly sortValue: string | null | undefined
  @Prop(String) readonly search: string | null | undefined

  private insidePage: number = 1
  private activeWorkStruct: IWorkStruct | null = null
  private loadingWork: boolean = true
  private loadingWorkStruct: boolean = true

  async mounted() {
    await this.fetchWorkStructs({project_id: this.selectedProject})
    this.loadingWorkStruct = false
    if (this.workStructTree.length) {
      this.changeActiveWorkStruct(this.workStructTree[0])
    }

    this.maxWidth = (this.$el as any).offsetWidth
    this.minWidth = (this.$refs as any).asideColumn.$el.clientWidth - 16
  }

  @Watch('sortValue')
  onChildChanged() {
    this.insidePage = 1
    this.changePage()
  }

  @Watch('search')
  @Debounce(1000)
  private changeSearch() {
    if (typeof this.search === 'string'
      && (this.search.length > 3 || this.search.length === 0)) {
      this.insidePage = 1
      this.changePage()
    }
  }

  private changeActiveWorkStruct(workStruct: IWorkStruct) {
    this.activeWorkStruct = workStruct
    this.changePage()
  }

  private expandActiveWorkStruct(id:number){
    if(this.$refs.aside){
      (this.$refs.aside as any).expandWorkStructTree(id)
    }
  }


  private changePage() {
    this.loadingWork = true
    let params = {
      project: this.selectedProject,
      insidePage: this.insidePage,
      workStructId: this.activeWorkStruct?.id ?? null,
      search: this.search,
      offset: 0,
    }

    if (this.activeWorkStruct?.children) {
      params.offset = this.activeWorkStruct.children.length
    }

    if (this.sortValue) {
      let [direction, field] = this.sortValue.split('_')
      params[field + 'Sort'] = direction
    }

    this.fetchWorks(params).finally(() => {
      this.loadingWork = false
      this.$scrollToTop()
    })
  }

  get activeWorkStructChildren(){
    if (!this.activeWorkStruct) return []
    return this.workStructsList.filter((item) => item.parentId === this!.activeWorkStruct!.id)
  }

  get workStrictForThisPage() {
    const top = (this.insidePage * 8) - 1
    const down = top - 8 + 1
    return this.activeWorkStructChildren.filter((el, key) => key <= top && key >= down)
  }

  get workStructTree(): IWorkStruct[] {
    if (this.workStructsList.length) {
      const map: { [key: number]: IWorkStruct } = Object.assign({}, ...this.workStructsList.map((v: IWorkStruct) =>
        ({[v.id]: Object.assign({...v}, {children: []})})
      ))

      return Object.values(map).filter((v: IWorkStruct) =>
        !(v.parentId && map[v.parentId]?.children?.push(v))
      );
    }
    return [];
  }

  private workStructShowDialog: boolean = false
  private workStructAddEditTitle: string = ''
  private workStructEmpty: IWorkStruct = {id: 0, projectId: 0, parentId: null, name: ''}
  private workStructForAddEdit: IWorkStruct | null = null

  private addEditWorkStruct(
    {type = 'add', workStruct = null}
      : { type: 'edit' | 'add', workStruct: IWorkStruct | null }
  ) {
    if (type === 'add') {
      this.workStructAddEditTitle = 'Добавление структуры'
      this.workStructForAddEdit = Object.assign({}, this.workStructEmpty)
    } else if (type === 'edit') {

      this.workStructAddEditTitle = 'Изменение структуры'
      this.workStructForAddEdit = Object.assign({}, workStruct ?? this.workStructEmpty)
    }
    this.workStructShowDialog = true
  }


  private isResize: boolean = false;
  private x = 0;
  private width = 0;
  private resizeElement: HTMLElement | null = null
  private maxWidth = 0;
  private minWidth = 0;


  expandDown(e, element: HTMLElement) {
    this.isResize = true
    this.x = e.clientX
    this.resizeElement = element
    this.width = element.offsetWidth
  }

  @Watch('isResize')
  isResizeWatch(val: boolean) {
    const body = document.querySelector('#app') as HTMLElement

    if(!body) return;

    if (val) {
      body.addEventListener('mousemove', this.expandMove)
      body.addEventListener('mouseup', this.expandUp)
      body.addEventListener('mouseout', this.mouseOut)
      body.style.userSelect = 'none'
    } else {
      body.removeEventListener('mousemove', this.expandMove)
      body.removeEventListener('mouseup', this.expandUp)
      body.removeEventListener('mouseout', this.mouseOut)
      body.style.userSelect = ''
    }
    console.log('isResize', val)
  }

  expandUp() {
    this.isResize = false
  }

  expandMove(e) {
    if (!this.isResize) return
    if (!this.resizeElement) return;

    let newWidth = (this.width + (e.clientX - this.x))

    if (newWidth >= this.maxWidth) {
      this.resizeElement.style.width = this.maxWidth + 'px'
      return;
    }

    this.resizeElement.style.width = newWidth + 'px'
  }

  mouseOut(e) {
    if (e.currentTarget === e.target) {
      this.isResize = false
    }
  }
}
